.card {
  background: #ffffff;
  padding: 2em;
  border-radius: 12px;
  border: 1px solid #e1e4ec;
  box-shadow: 0 2px 11px rgba(0 0 0 / 0.08);
}

@media (max-width: 800px) {
  .card {
    padding: 1em;
  }
}

.padding-lg {
  padding: 2em;
}

.padding-md {
  padding: 1em;
}

.padding-sm {
  padding: 0.5em;
}
.padding-none {
  padding: 0;
}

.checked {
  border-color: #0c69f5;
}

.warning {
  background-color: #ffedbd;
  border-color: #ebc501;
}

.error {
  border-color: #bb0000;
  background-color: #ffdddd;
  stroke: #bb0000;
}

.green {
  border-color: #2d7a08;
  background-color: #ddf8df;
}
.orange {
  border-color: #ff9300;
  background-color: rgba(255, 147, 0, 0.45);
}

.disabled {
  background-color: rgba(121, 120, 120, 0.35);
  stroke: #000000;
}

.blue {
  background-color: #edf3fd;
  border-color: #1e3a67;
  stroke: #1e3a67;
}
.grey {
  background-color: #f4f6f8;
  border-color: #ced4de;
  stroke: #ced4de;
}

.action {
  cursor: pointer;
}

.action:hover {
  background: white;
  border-color: #0c69f5;
  color: #0c69f5;
  stroke: #0c69f5;
}
