.font_blue {
  background-color: #f2f6ff;
}

.columnSpace {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.dashedLine {
  border: 1px dashed #ced4de;
}

.grid2Cols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1em;
}

@media (max-width: 500px) {
  .grid2Cols {
    grid-template-columns: 1fr;
  }
}
