.pillStyle {
  cursor: pointer;
  font-size: inherit;
}

/* Hide the browser's default checkbox */
.pillStyle input {
  position: absolute;
  opacity: 0;
  cursor: inherit;
}

.pillStyle label {
  padding: 0.5em 2em;
  border: 1px solid #ced4de;
  border-radius: 100px;
  cursor: inherit;
  display: inline-block;
  background-color: white;
}

/* Show the checkmark when checked */
.pillStyle input:checked ~ label {
  background-color: #0c69f5;
  border-color: #0c69f5;
  color: white;
}

/* Show the checkmark when focused */
.pillStyle input:focus-visible ~ label {
  outline: 1px solid #0c69f5;
  outline-offset: 1px;
}
