.gridFinance {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 1em;
  column-gap: 1em;
}

.cardTitle {
  color: #1e3a67;
  font-weight: 500;
  font-size: 16px;
}
