.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  gap: 1em;
}

.stroke {
  stroke: #1e3a67;
}
