.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-top: 1em;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
