.gridDescription {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 1em;
  column-gap: 1em;
}

.cardTitle {
  color: #1e3a67;
  font-weight: 500;
  font-size: 16px;
}

.grid3Cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1em;
  column-gap: 3em;
}
