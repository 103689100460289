.nomBank {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.nomBank > :first-child {
  width: 36px;
  height: 36px;
}

.nomBank > :last-child {
  font-weight: 500;
  margin-left: 0.5em;
}

.checkbox {
  margin-top: 1em;
  font-size: inherit;
}

.checkbox input {
  position: absolute;
  opacity: 0;
}

.checkbox label > :first-child {
  cursor: pointer;
}

.checkbox input:checked ~ label > :first-child {
  background: #ecf4fa;
  border-color: #0c69f5;
}

.checkbox input:focus-visible ~ label > :first-child {
  outline: 1px solid #0c69f5;
  outline-offset: 1px;
}
