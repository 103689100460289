.button {
  --transition-duration: 200ms;
  background: transparent;
  border: none;
  width: 2rem;
  padding: 0;
  cursor: pointer;
  fill: #1e3a67;
  display: grid;
  place-items: center;
}

@media (min-width: 870px) {
  .button {
    display: none;
  }
}

.button:hover {
  background-color: #244f7d1c;
}

.button .line {
  transition:
    y var(--transition-duration) ease-in var(--transition-duration),
    rotate var(--transition-duration) ease-in,
    opacity 0ms var(--transition-duration);
  transform-origin: center;
}

.button[aria-expanded="true"] .line {
  transition:
    y var(--transition-duration) ease-in,
    rotate var(--transition-duration) ease-in var(--transition-duration),
    opacity 0ms var(--transition-duration);
}

.button[aria-expanded="true"] :is(.top, .bottom) {
  y: 45px;
}

.button[aria-expanded="true"] .top {
  rotate: 45deg;
}

.button[aria-expanded="true"] .middle {
  opacity: 0;
}

.button[aria-expanded="true"] .bottom {
  rotate: -45deg;
}
