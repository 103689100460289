.etats {
  display: grid;
  grid-template-columns: repeat(4, minmax(max-content, 1fr));
  gap: 0.3em;
}

@media (max-width: 550px) {
  .etats {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 350px) {
  .etats {
    grid-template-columns: 1fr;
  }
}
