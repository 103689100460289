.inputSearch {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 16px;
  margin-right: 10px;

  width: auto;
  height: 36px;

  /* Primary/White */
  background: #ffffff;
  /* Outline/Light Grey */
  border: 1px solid #d0d5e1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
  box-shadow: none;
  outline: none;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #d0d5e1;
  border-left: 1px solid #d0d5e1;
  border-bottom: 1px solid #d0d5e1;
}

.buttonBank {
  background: #ffffff;
  border: 1px solid #e1e4ec;
  border-radius: 8px;
  padding: 0.5em 1em;
  margin-bottom: 7px;
  cursor: pointer;
}

.buttonBank:hover {
  background: #eee;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 1em;
}

.img {
  margin-right: 0.5em;
  vertical-align: middle;
}

.arrow {
  vertical-align: middle;
  margin-left: 0.5em;
}
