.form button {
  width: 100%;
}

.rememberMe {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.error {
  background: #f8d7da;
  color: #842029;
  border-color: #f5c2c7;
  border-radius: 8px;
  margin-bottom: 1em;
  padding: 0.75em;
}
