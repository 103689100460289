@import url("https://fonts.cdnfonts.com/css/liberation-serif");

.editor {
  height: 500px;
  width: 100%;
  padding-bottom: 3em;
}

.editor :global .body {
  font-family: "Liberation Serif", serif;
}

.editor :global .ql-toolbar {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.editor :global .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.editor :global .signature {
  width: 150px;
}

.editor p,
.editor ol,
.editor ul,
.editor pre,
.editor blockquote,
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6 {
  margin: revert !important;
  padding: revert !important;
}

.editor :global .nomargin {
  margin: 0 !important;
}
