.card {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #edf3fd;
  padding: 1em;
}

.credits {
  font-weight: 500;
  font-size: 1.3em;
  color: #1e3a67;
}
