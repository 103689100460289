.row {
  --grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 0.5fr)
    0.3fr max-content 0.15fr;

  grid-template-columns: var(--grid-template-columns);
  display: grid;
  color: #282e37;
}

.head {
  padding: 5px;
  font-weight: 500;
  font-size: 0.8em;
  height: 3em;
  border-bottom: solid;
  align-content: center;
  border-bottom-color: #d0d5e1;
  border-bottom-width: 1px;
  color: #62738f;
}
