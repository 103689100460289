.card {
  border-radius: 8px;
  padding: 0.6em;
  margin: 0.2em;
  display: inline-block;
  font-size: 0.8rem;
}

.yellow {
  background-color: #fff5dd;
  color: #bb8700;
}

.green {
  background-color: #ddf8df;
  color: #2d7a08;
}

.purple {
  background-color: #f2edff;
  color: #712bca;
}

.red {
  background-color: #ffdddd;
  color: #bb0000;
}
