@font-face {
  font-family: "khmer";
  src: url("../../fonts/khmer-mn.ttf");
}

.side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.brand {
  font-family: "khmer", sans-serif;
  margin-top: 0.3em;
}

.right {
  background-color: white;
}

.left {
  font-size: 6rem;
  padding-block: 0.5em;
}

.form {
  width: 100%;
  max-width: 400px;
  padding-inline: 1em;
}

.form > h1,
.form > p {
  text-align: center;
}

.form > p {
  margin-bottom: 1em;
}

.title {
  font-size: 0.33em;
  font-weight: 700;
  text-align: center;
  color: #1e3a67;
  padding: 1em 0.5em 0;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1em;
  font-size: 1em;
}

.logo > svg {
  width: 1em;
  height: 1em;
}

.logo > p {
  color: #1e3a67;
}

@media (min-width: 800px) {
  .page {
    display: flex;
    align-items: center;
  }

  .side {
    height: 100vh;
  }

  .right {
    background-color: #edf3fd;
  }
}

@media (max-width: 414px) {
  .left {
    font-size: 4rem;
  }
}
