/* The switch - the box around the slider */
.switch {
  --transition-duration: 0.2s;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #ccc;
  transition: var(--transition-duration);
}

.slider.toggling {
  left: 20%;
  right: 20%;
}

.slider:before,
.slider.round:after {
  position: absolute;
  content: "";
  transition: var(--transition-duration);
}

.slider.round.toggling:after {
  height: 24px;
  width: 24px;
  left: 0;
  bottom: 0;
  border: 4px solid rgba(12, 105, 245, 0.6);
  border-bottom-color: transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

.slider:before {
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
}

.input:checked + .slider {
  background-color: #0c69f5;
}

.input:checked + .slider.toggling,
.slider.toggling {
  background-color: #ccc;
}

.input:focus + .slider {
  box-shadow: 0 0 1px #0c69f5;
}

.input:checked + .slider:before {
  transform: translateX(16px);
}

.slider.toggling:before,
.input:checked + .slider.toggling:before {
  transform: translateX(0px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before,
.slider.round:after {
  border-radius: 50%;
}
