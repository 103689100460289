@font-face {
  font-family: "khmer";
  src: url("../../fonts/khmer-mn.ttf");
}

.header {
  --border: 1px solid #cad8f0;
  --header-height: 50px;

  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #edf3fd;
  border-bottom: var(--border);
}

.impersonatedHeader {
  padding: 0.5em;
  background-color: orange;
  text-align: center;
}

.brand {
  font-family: "khmer", sans-serif;
  margin-top: 0.4em;
  margin-left: 0.2em;
}

.navList {
  padding: 0;
  margin: 0;
  background-color: #edf3fd;
  display: flex;
  gap: 0.6em;
}

.navList > li {
  list-style: none;
  font-weight: 500;
}

.navList > li:hover,
.logo:hover,
.active {
  background-color: #244f7d1c;
}

.disconnect {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.inactive {
  color: #5f6d82;
}

.active {
  color: #0c86f5;
}

.burgerLogo {
  border-right: var(--border);
  display: flex;
  gap: 0.5em;
  width: fit-content;
  height: var(--header-height);
}

.logo {
  display: flex;
  align-items: center;
  color: #042b6a;
  padding: 0.5em 0.5em;
}

@media (max-width: 870px) {
  .navList {
    position: absolute;
    margin-top: 1px;
    flex-direction: column;
    overflow: auto;

    transition:
      transform 400ms,
      visibility 400ms;
    height: calc(100vh - var(--header-height));
  }

  .navList[data-open="true"] {
    transform: translateX(0);
    visibility: visible;
  }

  .navList[data-open="false"] {
    transform: translateX(-100%);
    visibility: hidden;
  }

  .navList > li > a,
  .impersonate,
  .disconnect {
    display: block;
    padding: 1.2em 2.4em;
  }

  .push {
    border-top: var(--border);
  }

  .burgerLogo {
    padding-left: 1em;
  }
}

@media (min-width: 870px) {
  .nav {
    display: flex;
    width: var(--content-width);
    margin: auto;
  }

  .navList {
    flex-grow: 1;
  }

  .navList > li > a,
  .disconnect {
    display: flex;
    align-items: center;
    height: 100%;

    padding: 1em 0.5em;
  }

  .push {
    margin-left: auto;
  }

  .impersonate {
    display: flex;
    align-items: center;
    padding: 0.25em 0.5em;
  }
}
