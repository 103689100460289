.loader {
  --width: 1em;
  --color: #b5d1ff;

  display: block;
  width: var(--width);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  border: 2px solid var(--color);
  border-bottom-color: transparent;
  margin: auto;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
