.step {
  color: #0c69f5;
}

.lineContainer {
  position: relative;
  margin-block: 3em 2em;
}

.line {
  height: 1px;
  background-color: #e0e0e0;
}

.button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
