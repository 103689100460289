.delete {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}

.relative {
  position: relative;
  padding: 0.7em;
}
