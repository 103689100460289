.close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
}

.wrapper {
  position: relative;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 0.5fr max-content 1fr 1fr;
  gap: 0.5rem;
}
