.parent {
  display: grid;
  position: relative;
  overflow: hidden;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
}

.header {
  font-weight: 500;
  text-align: left;
  padding: 0.5em;
  width: 85%;
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.5em;
  margin-bottom: 1em;
}

.footer {
  background: #0c69f5;
  color: white;
  padding-block: 0.5em;
}

.nbSignatures,
.priceSignatures {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.nbSignatures > :last-child,
.priceSignatures {
  margin-top: 0.5em;
}

.nbSignatures > :last-child,
.priceSignatures > :first-child {
  font-weight: 500;
  font-size: 1.5em;
}

.ribbon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100px;
  background-color: #2f9200;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  padding-block: 0.1em;
  transform: rotate(45deg);
  translate: 50% -50%;
}
