.container {
  display: flex;
  border: 1px solid #d0d5e1;
  border-radius: 100px;
}

.container input {
  text-align: center;
  border: none;
  margin-right: 1px;
  border-radius: 100px 0 0 100px;
  padding-inline: 1em;
  width: 13ch;
}

.container button {
  border: none;
  background-color: inherit;
  border-radius: 0 100px 100px 0;
}

.container button svg {
  display: block;
}

.container input:focus-visible {
  outline: 1px solid #0c69f5;
  outline-offset: 0;
}

.container button:focus-visible {
  outline-offset: 2px;
}

.container button:hover,
.container button:focus-visible {
  outline: 1px solid green;
  outline-offset: 0;
}
