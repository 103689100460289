.fields {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
}

.edit {
  align-self: start;
  justify-self: end;
  flex-wrap: wrap;
  display: flex;
  gap: 1em;
}

.line {
  border-bottom: 1px solid #e1e4ec;
  padding-top: 20px;
}
