.dotsAction {
  cursor: pointer;
  width: 20px;
}

.dotsAction:hover > path {
  stroke: #000000;
}

.refreshing {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
