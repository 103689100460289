.edit {
  align-self: start;
  justify-self: end;
}

.bailPropertyBailleur {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1em;
}

.propertyBailleur {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 4;
  gap: 1em;
}

.propertyBailleur > * {
  flex-grow: 1;
  flex-basis: 300px;
}
