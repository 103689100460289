.header {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 1em;
  padding: 2em;
}

.button {
  margin-left: auto;
  align-self: center;
  text-align: end;
}

.tenantInfo {
  margin: auto;
}
