.carre {
  border-radius: 12px;
}

.rond {
  border-radius: 100px;
}

.name {
  width: 40px;
  text-align: center;
  font-size: 10px;
  line-height: 40px;
  margin-right: 2%;
  font-weight: 700;
}

.s0 {
  color: #0262bb;
  background: #dfeafd;
}

.s1 {
  color: #da335b;
  background: #da335b14;
}

.s2 {
  color: #2f9200;
  background: #e4f9df;
}

.s3 {
  color: #dd5102;
  background: #ffefe8;
}

.s4 {
  color: #712bca;
  background: #f2edff;
}

.s5 {
  color: #006970;
  background: #e3fcff;
}

.s6 {
  color: #946c52;
  background: #faf2ed;
}

.s7 {
  color: #5a5a5a;
  background: #ebebeb;
}

.s8 {
  color: #009266;
  background: #e3ffee;
}

.s9 {
  color: #c28102;
  background: #fff7e1;
}

.hover:hover {
  color: black;
  background: #0c69f5;
}
