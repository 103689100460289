.rowCard {
  position: relative;
  margin-bottom: 0.5em;
}

.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  padding-block: 1rem;
}

.disabledNote {
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  z-index: 2;
}

.disabledNote {
  opacity: 0;
}

.disabled:hover .disabledNote,
.disabledNote:focus-within {
  opacity: 1;
}

.dropDown {
  display: grid;
  place-content: center;
}

.disabled .dropDown {
  z-index: 3;
}

@media (min-width: 1022px) {
  .row {
    grid-template-columns: var(--grid-template-columns);
    display: grid;
    grid-column: 1 / -1;
    border-bottom: 1px solid #d0d5e1;
  }

  .row:not(.disabled):hover {
    background-color: rgba(202, 204, 215, 0.2);
  }

  .deepProperty {
    border: none;
  }

  .folderRow {
    padding-bottom: 0;
  }
}

.folderDisplay {
  padding-bottom: 1rem;
}

.folderLink {
  display: flex;
  justify-content: center;
  gap: 2px;
  position: absolute;
  top: -1rem;
  left: 0;
  width: 5rem;
}

.folderLink > div {
  height: 2rem;
  width: 1px;
  background-color: #ced4de;
}

.folderDivider {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: absolute;
  top: 0;
  left: 5rem;
  width: calc(100% - 5rem);
}

.folderDivider > div {
  border-bottom: 1px dashed #ced4de;
}

@supports (grid-template-columns: subgrid) {
  .row {
    grid-template-columns: subgrid;
  }
}

.preavis {
  color: #c78f00;
}

.card {
  display: none;
}

@media (max-width: 1022px) {
  .dropDown {
    position: absolute;
    top: 0.5em;
    right: 1em;
  }
}
