.disabledNote {
  position: sticky;
  top: 50vh;
  margin: auto;
  width: fit-content;
}

.overlay {
  background-color: rgb(0, 0, 0, 0.1);
}
