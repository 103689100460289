.control {
  margin-bottom: 0.5rem;
  width: auto;
}

.control textarea {
  background: white;
  border-radius: 8px;
  border: 1px solid #ced4de;
  font: inherit;
  padding-left: 0.5rem;
  box-shadow: none;
  outline: none;
}

.control textarea:focus {
  background: #f2f6ff;
  border-color: #0196d5;
}

.error {
  color: #842029 !important;
}

.error textarea {
  background: #f8d7da !important;
  color: inherit;
}

.error textarea:focus {
  border-color: #f5c2c7;
}

.textInput {
  width: 100%;
}
