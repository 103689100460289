.inputSearch {
  padding: 8px 16px 8px 0;
  border: 1px solid #d0d5e1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
  outline: none;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #d0d5e1;
  border-right: 0;
}

.inputGroup {
  display: flex;
  flex-wrap: wrap;
}
