.default {
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.column {
  flex-direction: column;
}

.row-wrap {
  flex-wrap: wrap;
}

.default > span {
  display: flex;
  flex: 1;
}

.button-style {
  background-color: #f0f3f7;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.button {
  gap: 1em;
}

.default input[type="radio"] {
  appearance: none;
  margin: 0;
}

/* Label  */
.default label {
  display: grid;
  place-items: center;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5em 1em;
  flex-grow: 1;
  cursor: pointer;
}

.button input[type="radio"] + label {
  border: 1px solid #e1e4ec;
}

.button input[data-color="yellow"] + label {
  background-color: #fff5dd;
  color: #bb8700;
}

.button input[data-color="green"] + label {
  background-color: #ddf8df;
  color: #2d7a08;
}

.button input[data-color="blue"] + label {
  background-color: #f2edff;
  color: #712bca;
}

.button input[data-color="red"] + label {
  background-color: #ffdddd;
  color: #bb0000;
}

/* ----- */

/* Label checked */
.default input[type="radio"]:checked + label {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
}

.default:has(:focus-visible) {
  outline: 2px solid #0c69f5;
}

.button-style input[type="radio"]:checked + label {
  background-color: white;
  color: #0c69f5;
}

.button input[type="radio"]:not([data-color]):checked + label {
  border-color: #0c69f5;
  color: #0c69f5;
}

.button input[type="radio"][data-color]:checked + label {
  border-color: #0c69f5;
  font-weight: bold;
}

/* ----- */

/* Label hover */
.button input[type="radio"]:not([data-color]):not(:disabled) + label:hover {
  border-color: #0c69f5;
  color: #0c69f5;
}

.button input[type="radio"][data-color] + label:hover {
  font-weight: bold;
}

/* ----- */

/* Label disabled */
.button input[type="radio"]:not([data-color]):disabled + label {
  background-color: #e6e6e6;
  color: #656565;
}
/* ----- */
