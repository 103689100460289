.blackFridayCard {
  background-color: #ff843c;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;
}

.firstRow {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 20px;
}

.lastRow {
  font-size: 0.8em;
  font-weight: 500;
  line-height: 18px;
  margin-top: 0.5em;
}
