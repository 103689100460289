.tablist {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
}

.tab {
  color: #5f6d82;
  padding: 0 0 0.5em;
  display: block;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.tab.error {
  color: #bb0000;
}

.tab[aria-selected="true"] {
  color: #0c86f5;
  border-bottom: 3px solid #0c86f5;
}

.tab.error[aria-selected="true"] {
  color: #bb0000;
  border-bottom-color: #bb0000;
}

.tab:hover,
.tab:focus-visible {
  color: #0c86f5;
  outline: none;
}

.tabPanel {
  outline: none;
}
