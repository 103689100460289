.gridActions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 1em;
}

.archivedProfiles {
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
}

.archivedProfiles:hover {
  outline: 1px solid #cad8f0;
  cursor: pointer;
}

.disabledNote {
  position: sticky;
  top: 50vh;
  margin: auto;
  width: fit-content;
}

.overlay {
  background-color: rgb(0, 0, 0, 0.1);
}
