.wrapper {
  display: grid;
  grid-template-columns: 10em 1fr 4em;
  gap: 1em;
}

.firstCol {
  grid-column: 1;
}

.secondCol {
  grid-column: 2;
}

.thirdCol {
  grid-column: -2;
  grid-row: 1;
  justify-self: end;
}

.card {
  background-color: #edf3fd;
  margin-bottom: 2em;
  position: relative;
  padding: 1em;
}

.croix {
  padding: 0.5em;
  margin-bottom: 1em;
  position: absolute;
  top: 0;
  right: 0.3em;
}

@media (max-width: 920px) {
  .wrapper {
    grid-template-columns: minmax(5em, 10em) minmax(4em, 1fr);
  }

  .secondCol {
    grid-column: 1/-1;
    grid-row: 2;
  }
}
