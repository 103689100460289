.button svg {
  display: block;
}

.button {
  padding: 10px;
}

.iconOnly {
  border: none;
}
