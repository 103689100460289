.dialog {
  position: absolute;
  border: 1px solid #d0d5e1;
  inset: unset;
  right: 0;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  z-index: 100;
  white-space: nowrap;
}

.menu {
  position: relative;
}

.dropDown > div,
.dropDown > ul > li {
  padding: 0.5em 1em;
  border-bottom: 1px solid #d0d5e1;
  cursor: pointer;
}

.dropDown > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropDown > div:hover,
.dropDown > ul > li:hover {
  background-color: #d0d5e1;
}

.dropDown > div:last-child,
.dropDown > ul > li:last-child {
  border: none;
}
