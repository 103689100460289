.button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.button > svg {
  display: block;
}

.previewImg {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 90vh;
}

.previewPdf {
  display: grid;
  place-content: center;
  object-fit: contain;
  width: 90vw;
  height: 90vh;
}
