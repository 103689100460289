.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 560px) {
  .content {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
