.control {
  width: auto;
}

.control input {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ced4de;
  height: 2.5rem;
  font: inherit;
  padding-left: 0.5rem;
  box-shadow: none;
  outline: none;
}

.control input:focus {
  background: #f2f6ff;
  border-color: #0196d5;
}

.error {
  color: #842029 !important;
  border-color: #842029 !important;
}

.error input {
  background: #f8d7da !important;
  color: inherit;
}

.error input:focus {
  border-color: #f5c2c7;
}

.textInput {
  width: 100%;
}

.textInput:disabled {
  background-color: #e6e6e6;
}

.inputGroup {
  display: flex;
}

.inputUnit {
  text-align: center;
  white-space: nowrap;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #d0d5e1;
  border-left: none;
  padding: 0.6rem 0.75rem;
  background: #f2f6ff;
  color: #707989;
}

.xs {
  max-width: 3em;
}

.sm {
  max-width: 6em;
}

.md {
  max-width: 9em;
}

.inputField {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
