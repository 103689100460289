.croix {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 10px;
  width: 1em;
}

.wrapper {
  --bg-color: black;

  position: relative;
  background-color: var(--bg-color);
  border-radius: 8px;
  overflow: hidden;
}

.wrapper > img {
  display: block;
  transform: scale(1.01);
}

.thumbnail {
  width: 100px;
  aspect-ratio: 1;
  object-fit: contain;
  border: solid 1px #cbcbcb;
  border-radius: 8px;
}
