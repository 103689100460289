.control {
  width: auto;
}

.selectWrapper > select {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ced4de;
  height: 2.5rem;
  width: 100%;
  font: inherit;
  padding-left: 0.5rem;
  padding-right: 2rem;
  outline: none;
  appearance: none;
}

.selectWrapper {
  position: relative;
}

.selectWrapper > svg {
  position: absolute;
  display: block;
  vertical-align: top;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  left: calc(100% - 1.5rem);
}

.selectWrapper > select:focus {
  border-color: #0196d5;
  background-color: #f2f6ff;
}

.selectWrapper.error > select {
  background-color: #f8d7da;
}

.selectWrapper.error > svg {
  fill: #842029;
}

.selectWrapper.error > select:focus {
  border-color: #842029;
}

.selectWrapper > select:disabled {
  border-color: #e6e6e6;
  background-color: #e6e6e6;
}

.selectWrapper > select:disabled + svg {
  fill: gray;
}
