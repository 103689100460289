.wrapper {
  border-radius: 8px;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1em;
}

.field {
  display: flex;
  gap: 0.5em;
  align-items: flex-end;
}

.icon {
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f4f6f8;
}
