.wrapper {
  display: flex;
  align-items: center;
}

.smallImage {
  width: 5rem;
  border-radius: 8px;
  height: auto;
  max-height: 3rem;
  object-fit: cover;
  object-position: bottom;
}
