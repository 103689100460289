.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -100;
}

.inputFile + label {
  display: block;
  background: #edf3fd;
  border-radius: 8px;
}

.smallImage {
  width: 120px;
  border-radius: 8px;
}

.inputFile:focus + label,
.inputFile + label:hover {
  background: #ecf4fa;
}

.inputFile + label {
  cursor: pointer;
}

.icon {
  padding: 6px;
  margin: 10px;
  background: #ecf4fa;
  border-radius: 8px;
}

.icon > svg {
  display: block;
}

.primary {
  font-weight: 500;
  font-size: 0.875rem;
  color: #282e37;
}

.secondary {
  font-weight: 500;
  font-size: 0.6875rem;
  color: #525f7f;
}

.wrapper {
  display: flex;
  align-items: center;
}

.highlight {
  outline: solid 2px #0c69f5;
  border-radius: inherit;
}
