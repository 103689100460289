.grid2Cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 1em;
}

@media (max-width: 600px) {
  .grid2Cols {
    grid-template-columns: 1fr;
  }
}
