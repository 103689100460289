@import url("font.css");

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --max-content-width: 1143px;
  --content-width: min(90%, var(--max-content-width));
}

@media (max-width: 600px) {
  :root {
    --content-width: min(97%, var(--max-content-width));
  }
}

input {
  font: inherit;
}

html {
  font-family: "Cabinet Grotesk", sans-serif;
  font-weight: 400;
}

a {
  color: #0c69f5;
  text-decoration: none;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #0272a4;
}

button,
input {
  font: inherit;
}

p,
label {
  color: #282e37;
}

h2 {
  font-weight: 400;
  font-size: 28px;
  color: #1e3a67;
}

@media (max-width: 600px) {
  h2 {
    margin: 0;
  }
}

.bg-blue {
  background-color: #edf3fd;
}

.border-blue {
  border-bottom: 1px solid #cad8f0;
}

.noBorder {
  border: none;
}

.container {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.content {
  width: var(--content-width);
  margin: auto;
}

body,
p {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-right {
  justify-content: right;
}

.wrap {
  flex-wrap: wrap;
}

.gap10 {
  gap: 1em;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.ws-nowrap {
  white-space: nowrap;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.margin-form {
  margin-bottom: 1em;
}

.wMax-12em {
  max-width: 12em;
}

.w-100 {
  width: 100%;
}

.w-570 {
  width: 570px;
}

.w-800 {
  width: 800px;
}

.w-23 {
  width: 23%;
}

.w-30 {
  width: 30%;
}

.w-15em {
  width: 15em;
}

.w-30em {
  width: 30em;
}

.w-5em {
  width: 5em;
}

.h-5em {
  height: 5em;
}

.h-3em {
  height: 3em;
}

.text-center {
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-top {
  vertical-align: top;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.push {
  margin-left: auto;
}

.inline-block {
  display: inline-block;
}

.marginAuto {
  margin: auto;
}

.marginRAuto {
  margin-right: auto;
}

.marginL2 {
  margin-left: 0.2em;
}

.marginLAuto {
  margin-left: auto;
}

.marginL5 {
  margin-left: 0.5em;
}

.marginL10 {
  margin-left: 1em;
}

.marginL20 {
  margin-left: 2em;
}

.marginT-20 {
  margin-top: -2em;
}

.marginT5 {
  margin-top: 0.5em;
}

.marginT10 {
  margin-top: 1em;
}

.marginT0 {
  margin-top: 0;
}

.marginT20 {
  margin-top: 2em;
}

.marginT30 {
  margin-top: 3em;
}

.marginR5 {
  margin-right: 0.5em;
}

.marginR10 {
  margin-right: 1em;
}

.marginR20 {
  margin-right: 2em;
}

.marginB0 {
  margin-bottom: 0;
}

.marginB2 {
  margin-bottom: 0.2em;
}

.marginB5 {
  margin-bottom: 0.5em;
}

.marginB10 {
  margin-bottom: 1em;
}

.marginB20 {
  margin-bottom: 2em;
}

.margin0 {
  margin: 0;
}

.margin5 {
  margin: 0.5em;
}

.margin10 {
  margin: 1em;
}

.margin20 {
  margin: 2em;
}

.padding0 {
  padding: 0;
}

.padding5 {
  padding: 0.5em;
}

.padding10 {
  padding: 1em;
}

.padding20 {
  padding: 2em;
}

.paddingT10 {
  padding-top: 1em;
}

.paddingT20 {
  padding-top: 2em;
}

.paddingL5 {
  padding-left: 0.5em;
}

.paddingL10 {
  padding-left: 1em;
}

.paddingR5 {
  padding-right: 0.5em;
}

.paddingR10 {
  padding-right: 1em;
}

.paddingL20 {
  padding-left: 2em;
}

.paddingR20 {
  padding-right: 2em;
}

.paddingB0 {
  padding-bottom: 0;
}

.paddingB10 {
  padding-bottom: 1em;
}

.paddingB20 {
  padding-bottom: 2em;
}

.paddingB40 {
  padding-bottom: 4em;
}

.cursorPointer {
  cursor: pointer;
}

.absolute {
  position: absolute;
}

table {
  border-collapse: collapse;
}

.rounded {
  border-radius: 8px;
}

.fixed_top {
  position: fixed;
  height: 90%;
}

.primaryText {
  font-weight: 700;
  font-size: 16px;
  color: #282e37;
}

.secondaryText {
  font-weight: 500;
  font-size: 14px;
  color: #707989;
}

.titlePage {
  color: #1e3a67;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
}

.overflowScroll {
  overflow: scroll;
}

.italique {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.blueSpace {
  background-color: #edf3fd;
  height: 4em;
  margin-bottom: -2em;
}

.blueStar {
  color: #0c69f5;
}

.warning {
  color: #c78f00;
}

.error {
  color: #c72d2d;
}

.green {
  color: #008836;
}

.blue {
  color: #31599a;
}

.text-right {
  text-align: right;
}

.blueText {
  color: #0c69f5;
}

.cardTitle {
  color: #1e3a67;
  font-weight: 500;
  font-size: 1.3rem;
}

.primaryBlue {
  color: #1e3a67;
}

.smallText {
  font-size: 0.9rem;
}

.smallIcon svg {
  width: 1rem;
  height: 1rem;
}

.smallImage {
  width: 350px;
}

.gridColWrap240 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1em;
}
.gridColWrap300 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em;
}
.gridColWrap120 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1em;
}

.pre-whitespace {
  font: inherit;
  color: inherit;
  white-space: pre-wrap;
  overflow: auto;
}

.version-footer {
  position: fixed;
  bottom: 0;
  padding: 0.5em;
  width: 100%;
  box-shadow: 0 0 10px rgba(0 0 0 / 0.2);
  background-color: white;
  text-align: center;
}
