.wrapper {
  display: grid;
  grid-template-columns: 62% 38%;
  grid-template-rows: repeat(2, auto);
  gap: 0.5em;
  align-items: center;
  flex-grow: 1;
}

.header {
  grid-column: 1 / -1;
  grid-row-start: 1;
}
