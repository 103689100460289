.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1em;
}

@media (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}

.subscriptionDesc {
  font-size: 1.75rem;
  font-weight: 500;
}

.subscriptionPrice {
  display: flex;
  gap: 0.5rem;
  color: #1e3a67;
  align-items: center;
  font-weight: bold;
  text-wrap: nowrap;
}

.priceSymbol {
  font-size: 1.5rem;
}

.priceInt {
  font-size: 2rem;
}

.priceDec {
  font-size: 0.8rem;
  line-height: 1;
}

.bgWhite {
  background-color: white;
}
