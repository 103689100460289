.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 1em;
}

.sharedRoom {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 0.7em;
  border: solid 1px #2d7a08;
  border-radius: 4px;
  color: #2d7a08;
  padding: 0.1em;
}

.relative {
  position: relative;
  width: 100%;
}
