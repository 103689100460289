.card {
  background: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  stroke: #707989;
  fill: #707989;
}

.border-solid {
  border: 1px solid #e1e4ec;
  box-shadow: 0 2px 11px rgba(0 0 0 / 0.08);
}

.border-none {
  border: none;
  box-shadow: none;
}

@media (max-width: 800px) {
  .card {
    padding: 1em;
  }
}

.card:hover {
  background: white;
  border-color: #0c69f5;
  color: #0c69f5;
  stroke: #0c69f5;
  fill: #0c69f5;
}

.selected {
  background: white;
  border-color: #0c69f5;
  color: #0c69f5;
  stroke: #0c69f5;
  fill: #0c69f5;
}

.card:disabled {
  background-color: rgb(225, 225, 225);
  stroke: #707989;
  fill: #707989;
  cursor: default;
  border-color: rgb(225, 225, 225);
  color: #707989;
}

.invalid,
.valid {
  position: relative;
}

.invalid::before,
.valid::before {
  content: "";
  position: absolute;
  inset: 1em auto 1em 0.5em;
  width: 0.3rem;
  border-radius: 100vh;
}

.valid::before {
  background-color: #feefef;
}

.invalid::before {
  background-color: #e4f9df;
}

.warning {
  background-color: rgba(241, 221, 160, 0.27);
  border-color: #ebc501;
  color: #ebc501;
}

.error {
  border-color: #bb0000;
  background-color: #ffdddd;
  color: #bb0000;
  stroke: #bb0000;
  fill: #bb0000;
}

.green {
  border-color: #2d7a08;
  background-color: #ddf8df;
  color: #2d7a08;
}

.blue {
  background-color: #edf3fd;
  color: #1e3a67;
  font-weight: 500;
  stroke: #1e3a67;
  fill: #1e3a67;
}

.flat {
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

.flat:hover {
  background-color: hsl(0 0% 0% / 0.05);
}

.flat:disabled {
  background-color: transparent;
}

.block {
  display: block;
  width: 100%;
}

.padding-xs {
  padding: 0.5em;
}

.padding-sm {
  padding: 1em;
}

.padding-xl {
  padding: 2em;
}

.padding-none {
  padding: 0;
}
