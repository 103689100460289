.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.cardTitle {
  color: #1e3a67;
  font-weight: 500;
  font-size: 16px;
}

.secondaryText {
  color: #707989;
  font-weight: 300;
  font-size: 10px;
}

.primaryText {
  font-weight: 700;
  font-size: 16px;
  color: #282e37;
}

.secondaryText2 {
  font-weight: 500;
  font-size: 14px;
  color: #707989;
}

.thead {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #47679b;
}

.tr {
  border-bottom: solid;
  border-bottom: red;
}

.smallImage {
  width: 5em;
  height: auto;
  max-height: 5rem;
  object-fit: cover;
  object-position: bottom;
}
