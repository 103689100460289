.input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -100;
}

.label {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 1em;
  background: #edf3fd;
  border-radius: 8px;
  cursor: pointer;
}

.label.error {
  background: #f8d7da;
  border-color: #842029;
}

.error {
  color: #842029;
}

.highlight,
.input:focus + .label,
.label:hover {
  background: #ecf4fa;
}

.highlight,
.input:focus + .label {
  outline: solid 2px #0c69f5;
}

.smallImage {
  width: 120px;
  border-radius: 8px;
}

.icon {
  border-radius: 8px;
}

.icon > svg {
  display: block;
}

.primary {
  font-weight: 500;
  font-size: 0.875rem;
  color: #282e37;
}

.secondary {
  font-weight: 500;
  font-size: 0.6875rem;
  color: #525f7f;
}
