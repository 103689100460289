.modal {
  --padding: 2.5em;
  padding: 0;
  max-height: 90vh;
  max-width: 90vw;
  border-radius: 8px;
  border: none;

  /* hack for session replay to display modal */
  position: fixed;
  inset-block: 0;
  overflow: auto;
}

/* hack for session replay to display modal */
.modal[open] {
  display: block;
}

.modal::backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.header {
  padding: var(--padding);
  padding-bottom: 0;
  background: white;
}

.header.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.body {
  padding: var(--padding);
  padding-top: 0;
}

.close {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}

.close > svg {
  display: block;
}

.ultra {
  width: 1000px;
}

.big {
  width: 800px;
}

.intermediate {
  width: 700px;
}

.medium {
  width: 600px;
}

.mcw-xs {
  max-width: 300px;
}
.mcw-sm {
  max-width: 400px;
}
.mcw-md {
  max-width: 500px;
}
.mcw-lg {
  max-width: 600px;
}
.mcw-xl {
  max-width: 700px;
}

.content {
  margin: auto;
}

.blueHeader {
  background-color: #edf3fd;
  border-bottom: 1px solid #cad8f0;
}

@media (max-width: 800px) {
  .modal {
    --padding: 1em;
  }

  .close {
    top: 0.4em;
    right: 0.4em;
  }
}

.modal.noPadding {
  --padding: 0;
}
