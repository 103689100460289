.logo {
  position: absolute;
  margin: 0.5em;
}

.text,
.number {
  font-weight: 500;
  color: #1e3a67;
  text-align: center;
}

.number {
  font-size: 40px;
}

.text {
  font-size: 20px;
}

.body {
  margin: 2em 1em 1em;
}

.warning {
  color: #c78f00;
}

.error {
  color: #c72d2d;
}
