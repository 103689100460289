.title {
  display: flex;
  align-items: center;
  background-color: #edf3fd;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.push {
  margin-left: auto;
}

.noBorder {
  border: none;
}
