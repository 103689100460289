.card {
  background: #ffffff;
  border: 1px solid #e1e4ec;
  border-radius: 12px;
}

.padding {
  padding: 1em;
}

.nomBank {
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
  color: #282e37;
}

.nomCompte {
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  color: #707989;
}

.interrogation {
  margin-left: 0.5em;
}

.status {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #2f9200;
  padding: 5px;
}

.montantLabel {
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #707989;
  margin-top: 15px;
}

.montant {
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #282e37;
}

.expired {
  margin: 0;
}

.expired,
.active {
  border-top: 1px solid #e1e4ec;
  padding: 15px;
  font-weight: 500;
  font-size: 13px;
}

.expired > button {
  border: none;
  padding: 0;
  margin: 0;
  color: #0c69f5;
  cursor: pointer;
  background: transparent;
}

.push {
  margin-left: auto;
  padding-left: 15px;
}

.active {
  color: #006f2c;
}

.logo {
  width: 36px;
  height: 36px;
}
