.wrapper {
  position: relative;
}

.handle {
  cursor: move;
  position: absolute;
  inset: 10px;
  width: 10px;
  z-index: 1;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 20 20" height="5px" width="5px"><circle r="5" fill="%239D9D9D"/></svg>');
  background-repeat: space;
}
