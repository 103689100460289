.button {
  padding: 0.5em 1em;
  border-radius: 8px;
}

.secondary {
  background-color: #ffffff;
  border: 1px solid #ced4de;
  color: #282e37;
}

.secondary:hover {
  background: #ced4de;
}

.button:disabled {
  background: #8c94a1;
}
