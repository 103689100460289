.textCard {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1e3a67;
}

.smallText {
  font-size: 0.8rem;
  align-content: center;
  color: #62738f;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: 1em;
}

.body {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.raccourcis {
  flex-grow: 1;
}

.evenements {
  flex-grow: 4;
}
