.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1em;
}

@media (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
