.gridTenantEtFinance {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  row-gap: 1em;
  column-gap: 1em;
}

.cardTitle {
  color: #1e3a67;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 415px) {
  .gridTenantEtFinance {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
