.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.2fr;
  gap: 1em;
  align-items: center;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr 1fr 0.2fr;
  }
}
