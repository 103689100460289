/**
 * @license
 *
 * Font Family: Cabinet Grotesk
 * Designed by: ITF
 * URL: https://www.fontshare.com/fonts/cabinet-grotesk
 * © 2022 Indian Type Foundry
 *
*/

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Thin.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Thin.woff") format("woff"),
    url("fonts/CabinetGrotesk-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Extralight.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Extralight.woff") format("woff"),
    url("fonts/CabinetGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Light.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Light.woff") format("woff"),
    url("fonts/CabinetGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Regular.woff") format("woff"),
    url("fonts/CabinetGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("fonts/CabinetGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Bold.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Bold.woff") format("woff"),
    url("fonts/CabinetGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Extrabold.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Extrabold.woff") format("woff"),
    url("fonts/CabinetGrotesk-Extrabold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src:
    url("fonts/CabinetGrotesk-Black.woff2") format("woff2"),
    url("fonts/CabinetGrotesk-Black.woff") format("woff"),
    url("fonts/CabinetGrotesk-Black.ttf") format("truetype");
  font-weight: 900;
}
