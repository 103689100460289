.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-template-rows: repeat(5, min-content);
  gap: 1em;
}

.configPanel {
  display: grid;
  grid-row: span 5;
  grid-template-rows: subgrid;
}

.greyBackground {
  background-color: #f4f6f8;
}

@media (max-width: 450px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
